import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import router from '../router';
import axios from 'axios';
import SecureLS from 'secure-ls';
import common from './modules/common';
import bonus_request from './modules/bonus_request.js';
import packages from './modules/packages';
import bonuses from './modules/bonuses';
import logs from './modules/logs';
import approvers from './modules/approvers';
import welcome_messages from './modules/welcome_messages';
import ms_auth from './modules/ms_auth';

import {
  fetchCompanies,
  fetchEmployeeDetails,
} from '../services/modules/common';

import groups from './modules/groups';
import users_employees from './modules/users_employees.js';
import roles_permissions from './modules/roles_permissions';

// instanstiate secure local storage
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

const state = {
  users: null,
  isAuthenticated: false,
  currentUser: null,
  employeeData: null,
  employeeBonuses: null,
  language: null,
  companies: [],
  companiesLoadError: null,
  notificationMessagesArray: [],

  // for deedback utility
  feedbackSnackShowing: false,
  feedbackSnackColor: '',
  feedbackSnackText: '',
};

const defaultState = {
  users: null,
  isAuthenticated: false,
  currentUser: null,
  employeeData: null,
  employeeBonuses: null,
  language: null,
  companies: [],
  companiesLoadError: null,
  error: false,
  notificationMessagesArray: [],

  feedbackSnackShowing: false,
  feedbackSnackColor: '',
  feedbackSnackText: '',
};

const mutations = {
  RESET_SNACK_BAR: (state) => {
    state.feedbackSnackShowing = false;
    state.feedbackSnackColor = '';
    state.feedbackSnackText = '';
  },

  setError: (state, value) => {
    state.feedbackSnackShowing = true;
    state.feedbackSnackColor = value.color;
    state.feedbackSnackText = value.text;
  },

  doLogin: (state, value) => {
    state.isAuthenticated = value.isAuthenticated;
    state.currentUser = value.currentUser;
    state.employeeData = value.employeeData;
  },

  ggleLogin: (state, value) => {
    state.isAuthenticated = value.isAuthenticated;
    state.currentUser = value.currentUser;
  },

  doLogout: (state) => {
    state.currentUser = {};
    state.users = [];
    state.isAuthenticated = false;
    axios.defaults.headers.common['Authorization'] = null;
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('employee');
  },

  setEmployeeBonuses: (state, value) => {
    state.employeeBonuses = value;
  },

  SET_AUTHENTICATING_STATUS(state, status) {
    state.authenticating = status;
  },

  SET_CURRENT_USER(state, data) {
    state.currentUser = data;
  },

  // ? Here for backward compatibility.
  SET_EMPLOYEE_DATA(state, data) {
    localStorage.setItem('employee', JSON.stringify(data));
    state.employeeData = data;
  },

  SET_LOADING_EMPLOYEE_DATA_STATUS(state, value) {
    state.loadingEmployeeData = value;
  },

  SET_EMPLOYEE_DATA_ERRORED_STATUS(state, value) {
    state.loadingEmployeeDataErrored = value;
  },

  SET_EMPLOYEE_DATA_ERROR(state, message) {
    state.loadingEmployeeDataError = message;
  },

  SET_FEEDBACK_VALUES(state, value) {
    state.feedbackSnackShowing = true;
    state.feedbackSnackColor = value.snackColor;
    state.feedbackSnackText = value.message;
  },

  SET_MS_AUTHENTICATED_STATUS(state, value) {
    if (value) {
      localStorage.setItem('ad', 1);
    } else {
      localStorage.setItem('ad', 0);
    }
    state.msAuthenticated = value;
  },

  SET_ACCESS_TOKEN(state, token) {
    state.accessToken = token;
  },

  clearState(state) {
    Object.assign(state, defaultState);
  },

  SET_COMPANIES_DATA(state, data) {
    state.companies = data;
  },

  SET_COMPANIES_LOADING_ERROR(state, err) {
    state.companiesLoadError = err;
  },

  notificationsReceiver: (state, value) => {
    state.notificationMessagesArray = value;
  },
};

const actions = {
  clearState({ commit }) {
    commit('clearState');
  },

  notificationsReceiver({ commit }, val) {
    commit('notificationsReceiver', val);
  },

  notificationMessages({ commit }) {
    commit('notificationMessages');
  },

  resetSnackBar({ commit }) {
    commit('RESET_SNACK_BAR');
  },

  setError({ commit, dispatch }, payload) {
    commit('setError', payload);
    setTimeout(() => {
      dispatch('resetSnackBar');
    }, 4000);
  },

  async acquireEmployeeData({ dispatch, getters, commit }) {
    let payload = {};

    commit('SET_LOADING_EMPLOYEE_DATA_STATUS', true);
    dispatch('showFeedback', {
      status: 'submitting',
      message: 'Submitting request...',
    });

    try {
      const data = await fetchEmployeeDetails(getters.user.email);
      commit('SET_EMPLOYEE_DATA', data);

      const empLoggedIn = JSON.parse(localStorage.getItem('employee'));
      const user = {
        id: empLoggedIn.user,
        email: empLoggedIn.company_email,
      };

      payload = {
        isAuthenticated: true,
        currentUser: user,
        employeeData: empLoggedIn,
      };

      localStorage.setItem('user', JSON.stringify(user));

      if (empLoggedIn.user_group.length > 0) {
        if (
          empLoggedIn.user_group.some(
            (_property) => _property.title == 'company_hr'
          ) ||
          empLoggedIn.user_group.some(
            (_property) => _property.title === 'group_hr'
          )
        ) {
          // go to hr dashboard
          router.push({ name: 'hr-dashboard' });
        } else if (
          empLoggedIn.user_group.some(
            (_property) => _property.title === 'approver'
          )
        ) {
          router.push({ name: 'bonus-requests' });
        } else {
          // go to employee dashboard
          router.push({ name: 'home' });
        }
      } else {
        dispatch('logout');
        router.push({ name: 'login' });
      }

      commit('doLogin', payload);
      dispatch('showFeedback', {
        status: 'success',
        message: 'Successfully logged in',
      });
    } catch (error) {
      console.log(error);
      dispatch('showFeedback', {
        status: 'fail',
        message: 'Not authorized on this application!',
      });
    } finally {
      commit('SET_LOADING_EMPLOYEE_DATA_STATUS', false);
    }
  },

  login({ commit, dispatch }, user) {
    localStorage.clear();
    let payload = {};
    // using this._vm to access the current vue instance inside the store.
    return this._vm.$http
      .post('mmauth/api/login/', user)
      .then((response) => {
        const { token, user, employee } = response.data;

        payload = {
          isAuthenticated: true,
          currentUser: user,
          employeeData: employee,
        };
        //should be offloaded to a util func
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('employee', JSON.stringify(employee));

        let empLoggedIn = JSON.parse(localStorage.getItem('employee'));
        if (empLoggedIn.user_group.length > 0) {
          if (
            empLoggedIn.user_group.some(
              (_property) => _property.title == 'company_hr'
            ) ||
            empLoggedIn.user_group.some(
              (_property) => _property.title === 'group_hr'
            )
          ) {
            // go to hr dashboard
            router.push({ name: 'hr-dashboard' });
          } else if (
            empLoggedIn.user_group.some(
              (_property) => _property.title === 'approver'
            )
          ) {
            router.push({ name: 'bonus-requests' });
          } else {
            // go to employee dashboard
            router.push({ name: 'home' });
          }
        } else {
          this.logout();
          router.push({ name: 'login' });
        }

        commit('doLogin', payload);
        dispatch('showFeedback', {
          status: 'success',
          message: 'Successfully logged in',
        });

        return Promise.all([dispatch('fetchEmployeeBonuses')]);
      })
      .catch(() => {
        return Promise.reject('Invalid username or password');
      });
  },

  showFeedback({ commit, dispatch }, { status, message }) {
    let snackColor = '';
    if (status === 'submitting') {
      snackColor = 'info';
    } else if (status === 'success') {
      snackColor = 'success lighten-1';
    } else if (status === 'fail') {
      snackColor = 'red lighten-1';
    }
    commit('SET_FEEDBACK_VALUES', { snackColor, message });
    // clear the feedback after 4 seconds
    setTimeout(() => {
      dispatch('resetSnackBar');
    }, 4000);
  },
  // fetch currentUsers bonuses
  fetchEmployeeBonuses({ commit, state }) {
    this.loading = true;

    this._vm.$http
      .get(`bms/api/employees/${state.employeeData.id}/bonuses/`)
      .then((res) => {
        commit(
          'setEmployeeBonuses',
          res.data.map((item) => {
            return {
              id: item.id,
              bonus_id: item.bonus.id,
              bonus_code: item.bonus.bonus_id,
              activated: item.activated,
              company: item.bonus.company.name,
              description: item.bonus.description,
              package: item.bonus.package,
              bonus_status: item.bonus.active,
              pending_approval: item.pending_approval,
              bonus_approver: item.bonus_approver,
              comment: item.comment,
              discontinued: item.discontinued,
              requires_document_upload: item.bonus.requires_document,
              requires_email: item.bonus.requires_email,
              disabled: item.disabled,
              approved_at: item.approved_at,
              date_assigned: item.created_at,
            };
          })
        );
      })
      .catch(() => {})
      .finally(() => (this.loading = false));
  },
  // save access token
  saveAccessToken({ commit }, token) {
    localStorage.setItem('token', token);
    commit('SET_ACCESS_TOKEN', token);
  },

  logout: ({ dispatch, commit, getters }) => {
    // check is ms auth is true and initiates ad logout
    // otherwise
    // perform normal logout by flushing out the local storage
    // resetting all states.
    if (getters.isADuser) {
      // perform AD logout.
      dispatch('ms_auth/logout');
    } else {
      commit('doLogout');
      commit('clearState');
      // localStorage.clear();
      commit('logout');
      router.push({ name: 'login' });
      window.location.href = '/login';
      window.location.reload();
      return 'done';
    }
  },

  async loadCompanies({ commit }) {
    try {
      const data = await fetchCompanies();
      commit('SET_COMPANIES_DATA', data);
    } catch (error) {
      commit('SET_COMPANIES_LOADING_ERROR', error);
    }
  },
};

const getters = {
  getError(state) {
    return {
      feedbackSnackShowing: state.feedbackSnackShowing,
      feedbackSnackText: state.feedbackSnackText,
      feedbackSnackColor: state.feedbackSnackColor,
    };
  },

  // TODO: Do some mapping if necessary
  allCompanies: (state) => state.companies,
  getNofiticationMessages: (state) => state.notificationMessagesArray,
  isADuser: (state) => state.msAuthenticated,
  user: (state) => state.currentUser,
  employeeData: (state) => state.employeeData,
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    common,
    bonus_request,
    packages,
    bonuses,
    approvers,
    welcome_messages,
    groups,
    users_employees,
    roles_permissions,
    logs,
    ms_auth,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },

      reducer(_state) {
        if (_state.isAuthenticated) return _state;
        return {};
      },
    }),
  ],
});
