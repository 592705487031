import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';

export default {
  namespaced: true,
  state() {
    return {
      msalConfig: {
        auth: {
          clientId: process.env.VUE_APP_AD_CLIENT_ID,
          authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AD_TENANT_ID}`,
          redirectUri: process.env.VUE_APP_AD_SUCCESS_REDIRECT_URL,
          postLogoutRedirectUri: process.env.VUE_APP_AD_LOGOUT_REDIRECT_URL,
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      },
      account: {},
    };
  },

  getters: {
    userAccount: (state) => state.account,
    appMsal: (state) => new PublicClientApplication(state.msalConfig),
  },

  actions: {
    saveAccount({ dispatch, commit }, account) {
      commit('SET_ACCOUNT', account);
      let user = {
        first_name: account.name.split(' ')[0],
        last_name: account.name.split(' ')[1],
        id: account.localAccountId,
        email: account.username,
      };
      commit('SET_CURRENT_USER', user, { root: true });
      // set use is ad authenticated user.
      commit('SET_MS_AUTHENTICATED_STATUS', true, { root: true });
      dispatch('acquireToken');
    },
    acquireToken({ dispatch, getters }) {
      let accessTokenRequest = {
        account: getters.userAccount,
        scopes: [process.env.VUE_APP_AD_ACCESS_TOKEN_SCOPES],
      };
      let msalInstance = getters.appMsal;
      msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .then(function (accessTokenResponse) {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          // set the value for access token to use in other requests
          dispatch('saveAccessToken', accessToken, { root: true });
          dispatch('acquireEmployeeData', null, { root: true });
        })
        .catch(function (error) {
          //Acquire token silent failure, and send an interactive request
          if (error instanceof InteractionRequiredAuthError) {
            msalInstance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                // Acquire token interactive success
                let accessToken = accessTokenResponse.accessToken;
                // set the value for access token to use in other requests
                dispatch('saveAccessToken', accessToken, { root: true });
                dispatch('acquireEmployeeData', null, { root: true });
              })
              .catch(function (error) {
                // Acquire token interactive failure
                console.log(error);
              });
          }
        });
    },

    async logout({ getters }) {
      await getters.appMsal
        .logoutPopup({
          account: getters.appMsal.getAccountByHomeId(
            getters.userAccount.homeAccountId
          ),
          mainWindowRedirectUri: process.env.VUE_APP_AD_LOGOUT_REDIRECT_URL,
        })
        .then(() => {
          // TODO: Notify user was logged out.
          localStorage.clear();
          console.log('logging out');
        })
        .catch((error) => {
          // TODO: Notify user an error occurred.
          console.log(error);
        });
    },
  },

  mutations: {
    SET_ACCOUNT(state, account) {
      state.account = account;
    },
  },
};
