<template>
  <v-navigation-drawer v-model="drawerShowing" app color="accent">
    <v-list v-model="selectedItem" dense class="mt-0">
      <template>
        <div>
          <Language></Language>
        </div>
      </template>
      <v-list-item-group v-model="selectedItem" color="white">
        <div v-for="(item, i) in menuItems" :key="i">
          <v-list-item v-if="!item.subMenu" :key="i" :to="{ name: item.name }">
            <v-list-item-icon>
              <v-icon color="white">{{ item.icon }} </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                class="font-weight-bold text-capitalize"
                v-text="$t(item.text)"
              >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-else
            :key="item.text"
            :value="true"
            color="white"
            append-icon="mdi-chevron-down"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon color="white">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                class="font-weight-bold text-capitalize"
                @click="redirectToPage(item)"
                v-text="$t(item.text)"
              ></v-list-item-title>
            </template>

            <v-list-item
              v-for="subLink in item.subMenu"
              :key="subLink.text"
              :to="{ name: subLink.name }"
              class="pl-12"
            >
              <v-list-item-icon>
                <v-icon color="white" v-text="subLink.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-bold text-capitalize"
                  v-text="$t(subLink.text)"
                >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Language from './language.vue';

export default {
  components: {
    Language,
  },
  props: {
    drawer: Boolean,
  },
  data: () => ({
    selectedItem: 1,
    disabled: true,
    callSettings: false,
    passwordShow: false,

    items: [
      {
        text: 'dashboard',
        icon: 'mdi-view-dashboard-outline',
        name: 'hr-dashboard',
        page: '/hr/dashboard',
        hr: true,
      },
      {
        text: 'Dashboard',
        icon: 'mdi-view-dashboard-outline',
        name: 'home',
        employee: true,
      },
      {
        text: 'myBonuses',
        icon: 'mdi-ticket-outline',
        name: 'employee-bonus-tracker',
      },
      {
        text: 'bonusTracker',
        icon: 'mdi-clipboard-list-outline',
        name: 'employees-bonus-tracker',
        hr: true,
        employee: false,
      },
      {
        text: 'bonusRequests',
        icon: 'mdi-file-sign',
        name: 'bonus-requests',
        hr: true,
        approver: true,
      },
      {
        text: 'myBonuses',
        icon: 'mdi-ticket-outline',
        name: 'hr-bonuses',
        hr: true,
        approver: true,
      },
      {
        text: 'Settings',
        icon: 'mdi-cog-outline',
        hr: true,
        name: 'hr-settings',
        subMenu: [
          {
            text: 'user management',
            icon: 'mdi-account-cog-outline',
            hr: true,
            name: 'user-groups',
          },
          {
            text: 'employees',
            icon: 'mdi-account-multiple-outline',
            hr: true,
            name: 'manage-employees',
          },
          {
            text: 'logs',
            icon: 'mdi-lifebuoy',
            hr: true,
            name: 'manage-logs',
          },
        ],
      },
      // {
      //   text: 'settings',
      //   icon: 'mdi-cog-outline',
      //   name: 'employee-settings',
      //   employee: true,
      // },
    ],
  }),

  computed: {
    employeeData() {
      return this.$store.state.employeeData || {};
    },

    checkGroupHr() {
      return this.employeeData.user_group.some(
        (_property) => _property.title == 'group_hr'
      );
    },

    checkCompanyHr() {
      return this.employeeData.user_group.some(
        (_property) => _property.title == 'company_hr'
      );
    },

    checkApprover() {
      return this.employeeData.user_group.some(
        (_property) => _property.title == 'approver'
      );
    },

    // #TODO: refactor this code ;
    menuItems() {
      if (this.checkGroupHr || this.checkCompanyHr) {
        return this.items.filter((item) => item.hr);
      } else if (this.checkApprover) {
        return this.items.filter((item) => item.approver);
      } else {
        return this.items.filter((item) => !item.hr);
      }
    },

    drawerShowing: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit('update:drawer', value);
      },
    },
  },

  created() {
    console.log(
      'groups hr? ------->',
      this.$store.state.employeeData.user_group.some(
        (_property) =>
          _property.title == 'group_hr' ||
          this.employeeData.user_group.some(
            (_property) => _property.title == 'company_hr'
          )
      )
    );
  },

  methods: {
    redirectToPage(item) {
      this.$router.push({ name: `${item.name}` }).catch(() => {});
    },
    hr_dashboard() {
      this.$router.push({ name: 'hr_dashboard' });
    },
    tracker() {
      this.$router.push({ name: 'tracker' });
    },
    settings() {
      this.$router.push({ name: 'hr_dashboard' });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 12px;
}
.v-application--is-ltr
  .v-list--dense
  .v-list-group--sub-group
  .v-list-group__header {
  padding-left: 0px !important;
}
</style>
